<template>
	<a-card class="guest-container">
		<template #title>
			<a-alert message="资源管理" type="info" show-icon closable v-show="authAlert === 'open'" @close="closeAlert" style="margin-bottom: 10px">
				<template #icon><bulb-outlined style="margin-top: 20px" /></template>
				<template #description>
					<div class="alertContent">
						无论是某个功能、菜单、按钮的查看权、使用权，还是某些特定数据的访问权限，都属于一种资源（Resource）。
						<br />
						资源支持嵌套树形结构。管理员在这里可以新增、导入、删除、编辑资源，也可以为某资源关联到角色使用。
					</div>
				</template>
			</a-alert>
			<div class="header">
				<a-button @click="backUp" style="margin-right: 15px">返回</a-button>
				<a-button type="primary" @click="handleAdd(`parent`)"> 新增父级资源 </a-button>
				<a-input style="width: 180px; margin: 0 15px" v-model:value="searchName" @keydown.enter="getDataList" placeholder="请输入名称进行搜索" />

				<a-button type="primary" @click="getDataList">查询</a-button>
			</div>
		</template>
		<a-tabs v-model:activeKey="activeKey" @change="changeTab">
			<a-tab-pane key="PC" tab="菜单"></a-tab-pane>
			<a-tab-pane key="FUNC" tab="功能"></a-tab-pane>
		</a-tabs>
		<a-table
			:loading="loading"
			:columns="columns"
			rowKey="id"
			childrenColumnName="childrenList"
			:scroll="{ y: tableHeight }"
			:data-source="tableData"
			:pagination="false"
		>
			<template #bodyCell="{ column, record }">
				<template v-if="column.key === 'status'">
					<el-popconfirm
						confirm-button-text="确认"
						cancel-button-text="取消"
						title="当前操作将修改资源状态，是否继续"
						@confirm="confirm(record)"
						@cancel="cancel(record)"
					>
						<template #reference>
							<el-switch @click="clickSwitch(record)" v-model="record.status" />
						</template>
					</el-popconfirm>
				</template>

				<template v-if="column.key === 'type'">
					<span>{{ record.type == "FUNC" ? `功能` : `菜单` }}</span>
				</template>
				<template v-if="column.key === 'action'">
					<span>
						<a type="primary" class="mr10" @click="handleAdd(record)"> 新增子级资源 </a>
						<a @click="handleEdit(record)" class="mr10">编辑</a>
						<a @click="handleDelete(record)" class="mr10" style="color: Red">删除</a>
						<a @click="handleMove(record, -1)" class="mr10">上移</a>
						<a @click="handleMove(record, 1)">下移</a>
					</span>
				</template>
			</template>
		</a-table>
	</a-card>
	<a-drawer width="640" placement="right" v-model:visible="dialogVisible" @close="changeDiagle" :closable="true">
		<p class="pStyleTitle">
			{{ edit ? "编辑资源" : isParent ? "新增子级资源" : "新增父级资源" }}
		</p>
		<a-form :model="resource" name="basic" :label-col="{ span: 4 }" :wrapper-col="{ span: 16 }" autocomplete="off" @finish="onFinish">
			<a-form-item label="父级名称" v-show="isParent" name="parentName">
				<span>{{ resource.parentName }}</span>
			</a-form-item>
			<a-form-item label="资源名称" name="name" :rules="[{ required: true, message: '请输入资源名称' }]">
				<a-input v-model:value="resource.name" />
			</a-form-item>
			<a-form-item label="资源code" name="code" :rules="[{ required: true, message: '请输入资源code' }]">
				<a-input v-model:value="resource.code" />
			</a-form-item>
			<a-form-item label="权限类型">
				<a-radio-group v-model:value="resource.type" :disabled="edit" @change="resource.view = ''">
					<a-radio value="MENU">菜单</a-radio>
					<a-radio value="FUNC">功能</a-radio>
				</a-radio-group>
			</a-form-item>
			<a-form-item label="菜单类型" name="view" v-if="resource.type == 'MENU'" :rules="[{ required: false, message: '请选择菜单类型' }]">
				<a-select ref="select" v-model:value="resource.terminal">
					<a-select-option v-for="item in options" :key="item.value" :value="item.value">
						{{ item.name }}
					</a-select-option>
				</a-select>
			</a-form-item>
			<a-form-item v-if="resource.type == 'FUNC'" label="路由" name="view" :rules="[{ required: false, message: '请输入路由' }]">
				<!-- <a-input auto-size :placeholder="`输入多个路由，以空格分隔`" v-model:value="resource.viewList" /> -->
				<a-select v-model:value="resource.viewList" :options="viewOptions" mode="tags" 
        :token-separators="[',',' ']" placeholder="输入多个路由，以空格分隔"></a-select>
			</a-form-item>
			<a-form-item v-else label="路由" name="view" :rules="[{ required: false, message: '请输入路由' }]">
				<a-input v-model:value="resource.view" />
			</a-form-item>
			<a-form-item v-if="resource.type == 'FUNC' && dialogVisible" label="应用" name="view" :rules="[{ required: false, message: '请选择应用' }]">
				<SelectApps :app-code="resource.appCode" @change="changeAppCode" />
			</a-form-item>
			<a-form-item v-if="resource.type == 'FUNC' && dialogVisible" label="菜单" name="view" :rules="[{ required: false, message: '请选择应用' }]">
				<SelectMenu :authSysCode="query.code" @change="changeMenuIdList" :menu-id-list="resource.menuIdList" />
			</a-form-item>

			<a-form-item label="图标" name="note" :rules="[{ required: false, message: '请输入图标' }]">
				<a-input v-model:value="resource.icon" />
			</a-form-item>
			<a-form-item label="描述" name="note" :rules="[{ required: false, message: '请输入资源描述' }]">
				<a-input v-model:value="resource.note" />
			</a-form-item>
			<a-form-item label="应用商户" name="merchant">
				<a-radio-group v-model:value="resource.isCommon">
					<a-radio :value="1">全部</a-radio>
					<a-radio :value="0">指定</a-radio>
				</a-radio-group>
				<div class="mt10" v-if="resource.isCommon === 0">
					<SelectMerchant v-model:list="resource.assignMerchantIdList" />
				</div>
			</a-form-item>

			<a-form-item :wrapper-col="{ offset: 8, span: 16 }">
				<a-button type="primary" html-type="submit">提交</a-button>
			</a-form-item>
		</a-form>
	</a-drawer>
</template>

<script>
import { computed, onActivated, reactive, ref, toRefs } from "vue"
// import axios from "@/common/axios";
import { useRouter, useRoute } from "vue-router"
import useCopy from "@/hooks/useCopy"
import axios from "@/common/axios"
import api from "@/common/api/api"
import { useStore } from "vuex"
// import { message } from "ant-design-vue";
import { localGet, localSet } from "@/common/utils"
// import IdaasDescription from "@/components/IdaasDescription.vue";
import SelectApps from "@/components/SelectApps.vue"
import SelectMenu from "@/components/SelectMenu.vue"
import SelectMerchant from "@/components/SelectMerchant.vue"
import { message } from "ant-design-vue"
export default {
	name: "Application",
	components: {
		// IdaasDescription,,
		SelectApps,
		SelectMenu,
		SelectMerchant,
	},
	setup() {
		const multipleTable = ref(null)
		const router = useRouter()
		const route = useRoute()
		const store = useStore()
		const copy = useCopy
		const state = reactive({
			loading: false,
			activeKey: "PC",
			merchantAppId: "",
			tableData: [], // 数据列表
			tableDataAlert: [], // 数据列表
			selectList: [], // 选中项
			ouathDisable: false,
			viewOptions: [],
			columns: [
				{
					title: "资源名称",
					dataIndex: "name",
					width: 150,
					key: "name",
				},
				{
					title: "资源CODE",
					dataIndex: "code",
					key: "code",
				},
				{
					title: "图标",
					dataIndex: "icon",
					key: "icon",
				},
				{
					title: "路由",
					dataIndex: "view",
					key: "view",
				},
				{
					title: "资源类型",
					dataIndex: "type",
					key: "type",
					width: 100,
				},
				{
					title: "父级菜单",
					dataIndex: "parentName",
					key: "parentName",
				},
				{
					title: "资源状态",
					dataIndex: "status",
					key: "status",
					width: 100,
				},
				{
					title: "系统描述",
					dataIndex: "note",
					key: "note",
				},
				{
					title: "操作",
					key: "action",
					width: "250px",
				},
			],
		})
		onActivated(() => {
			getDataList()
		})
		const params = reactive({
			searchName: "",
			status: true,
			all: true, //是否需要全部权限
			resourceTypes: ["MENU"], //资源类型 MENU菜单 FUNC功能
			tree: false, //是否需要树形结构
			userId: "",
			total: 0, // 总条数
			page_no: 1, // 当前页
			page_size: 10, // 分页大小
			old_page_size: 10, //存储请求时的pagesize
		})
		// 获取列表
		const getDataList = () => {
			state.loading = true
			const data = {
				...params,
				name: params.searchName,
				tree: true,
				merchantId: store.getters["user/merchantId"],
				authSysCode: route.query.code,
			}
			delete data.total
			axios
				.post(api.resourceList, data)
				.then((res) => {
					state.tableData = res

					state.loading = false
					// params.total = res.total;
					// params.page_no = res.page_no;
					// params.page_size = res.page_size;
					// params.old_page_size = res.page_size;
				})
				.catch(() => {
					state.loading = false
				})
		}
		// 当前页 及当前页
		const handleChangePage = (page, pageSize) => {
			params.page_no = params.old_page_size === pageSize ? page : 1
			getDataList()
		}
		const options = ref([
			{
				name: "PC",
				value: "PC",
			},
			{
				name: "PDA",
				value: "PDA",
			},
		])
		const reset = () => {
			formData.resource = {
				name: "",
				id: "",
				code: "",
				note: "",
				merchantId: store.getters["user/merchantId"],
				status: true,
				authSysCode: route.query.code,
				children: [],
				leaf: true,
				param: "",
				parentId: 0, //父节点id *
				sortNum: "",
				type: "MENU",
				appCode: "",
				viewList: [],
				isCommon: 1,
				assignMerchantIdList: [],
			}
		}
		// 新增
		const handleAdd = (value) => {
			diagleDate.dialogVisible = true
			formData.edit = false
			// console.log(value);
			// debugger;
			// 判断是否编辑状态 ，编辑状态需要传腹肌
			if (value.id) {
				formData.isParent = true
				formData.resource = {}
				formData.resource.parentName = value.name
				formData.resource.parentId = value.id
				formData.resource.type = value.type || "MENU"
			} else if (value === "parent") {
				formData.isParent = false
				reset()
			} else {
				reset()
			}
		}
		// 编辑
		const handleEdit = (record) => {
			formData.edit = true
			record.status = true
			formData.resource = { ...record }
			formData.resource.viewList = formData.resource.viewList ? formData.resource.viewList : []
			// formData.resource = JSON.parse(JSON.stringify(id));
			diagleDate.dialogVisible = true
		}
		// 删除
		const handleDelete = (record) => {
			// console.log("record: ", record)
			axios
				.post(api.resourceDelete, {
					resourceId: record.id,
				})
				.then(() => {
					message.success("操作成功")
					getDataList()
				})
		}

		// 移动
		const handleMove = (record, num) => {
			let sortNum = record.sortNum + num
			console.log("sortNum: ", sortNum)
			axios
				.post(api.resourceSort, {
					resourceId: record.id,
					sortNum,
				})
				.then(() => {
					message.success("操作成功")
					getDataList()
				})
		}

		// 详情模板 控制页面显示隐藏
		const diagleDate = reactive({
			dialogVisible: false,
			authAlert: localGet("authAlert") || "open",
			visibleSecret: false,
			secretConfirm: false,
			selectApp: false, //控制选择系统的弹框
			secret: { clientId: "", clientSecret: "" },
		})
		const closeAlert = () => {
			diagleDate.authAlert = "close"
			localSet("authAlert", "close")
		}
		const changeDiagle = () => {
			diagleDate.dialogVisible = false
		}
		const formData = reactive({
			resource: {
				name: "",
				id: "",
				code: "",
				note: "",
				merchantId: store.getters["user/merchantId"],
				status: "",
				roleResourceList: [],
				appCode: undefined,
				menuIdList: [],
			},
			edit: false,
			isParent: true,
		})
		// 添加组织机构
		const onFinish = (value) => {
			// console.log(value);
			formData.resource.merchantId = store.getters["user/merchantId"]
			formData.resource.authSysCode = route.query.code
			// formData.resource.leaf = formData.resource;

			if (formData.resource.type == "FUNC") {
				formData.resource.terminal = ""
			}

			axios
				.post(api.resourceSave, {
					resource: formData.resource,
				})
				.then(() => {
					// console.log(res);
					message.success("操作成功")
					if (value !== "confirm") {
						getDataList()
					}

					diagleDate.dialogVisible = false
				})
		}

		const treeData = reactive({
			treeList: [],
			expandedKeys: [],
			selectedKeys: [],
			checkedKeys: [],
			nowKeys: {
				id: "",
				name: "",
			},
		})
		const clickSwitch = (value) => {
			// console.log(value);
			state.tableData.forEach((item) => {
				if (item.name == value.name) {
					item.status = !value.status
				}
			})
			// console.log(state.tableData);
		}
		const getTreeList = () => {
			// let data = {};
			// axios.post(api, data).then((res) => {
			//   console.log(res);
			// });
		}
		const confirm = (value) => {
			// console.log("confirm", value);
			state.tableData.forEach((item) => {
				if (item.name == value.name) {
					item.status = !value.status
				}
			})
			formData.resource = value
			onFinish("confirm")
		}
		const cancel = () => {
			// console.log("cancel");
			// getDataList();
		}

		const oauth = (value, type) => {
			router.push({ name: type })
		}
		const backUp = () => {
			router.push({ name: "authSystem" })
		}
		const tableHeight = computed(() => window.innerHeight - 350)
		const changeMenuIdList = (value) => {
			formData.resource.menuIdList = value
		}
		const changeAppCode = (value) => {
			formData.resource.appCode = value
		}
		const changeTab = (value) => {
			console.log(value)
			if (value == "PC") {
				params.resourceTypes = ["MENU"]
			} else if (value == "FUNC") {
				params.resourceTypes = ["FUNC"]
			}
			getDataList()
		}

		return {
			...toRefs(state),
			...toRefs(diagleDate),
			...toRefs(params),
			...toRefs(formData),
			...toRefs(treeData),
			...toRefs(route),
			options,
			changeTab,
			multipleTable,
			changeAppCode,
			changeMenuIdList,
			backUp,
			getDataList,
			handleAdd,
			clickSwitch,
			handleEdit,
			handleDelete,
			handleMove,
			handleChangePage,
			closeAlert,
			changeDiagle,
			onFinish,
			copy,
			getTreeList,
			confirm,
			cancel,
			oauth,
			tableHeight,
		}
	},
}
</script>
<style lang="scss" scoped>
.guest-container {
	min-height: 100%;
	padding-bottom: 10px;
}
.a-card.is-always-shadow {
	min-height: 100% !important;
}
.marginInfoList {
	margin: 10px 0;
	color: #6f7071;
	font-size: 14px;
	.infoLabel {
		text-align: center;
	}
	.infoValue {
		text-align: left;
	}
}
.cardBox {
	display: flex;
	justify-content: space-around;
	.ant-card-head-title {
		font-size: 12px;
	}
}
:deep(.ant-card) {
	width: 25% !important;
	height: 145px;
	font-size: 12px;
	margin: 0 10px;
	.ant-card-head {
		font-size: 12px;
		padding: 0 16px;
	}
	.ant-card-body {
		padding-bottom: 0;
		padding: 16px;
		.ant-btn {
			font-size: 12px;
		}
		.cardP {
			margin-bottom: 0;
			a {
				margin-right: 18px;
				&:hover {
					cursor: pointer;
				}
			}
		}
		.cardContent {
			color: #909399;
			height: 40px;
		}
	}
}
.alertContent {
	font-size: 12px;
}
.pStyle {
	font-size: 12px;
	color: #657180;
}
.pStyleTitle {
	font-size: 18px;
	font-weight: 900;
}
.secretStyle {
	font-weight: 900;
	margin-right: 20px;
}
.copy:hover {
	cursor: pointer;
}
.mt10 {
	margin-top: 10px;
}
</style>
